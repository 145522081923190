import { internalPost } from "@/common/http/httpServices";

export async function trackLogin(isInitial) {
  try {
    const init = {
      url: "/api/account/trackLogin",
      body: { isInitial: isInitial },
    };

    const { response } = await internalPost(init);

    return response;
  } catch (e) {
    console.error("Invalid API response:", e);
  }
}

export async function getFavouriteDashboard() {
  try {
    const init = {
      url: "/api/account/favDashboard",
    };

    const { response } = await internalPost(init);
    const data = await response.json();

    return data;
  } catch (e) {
    console.error("Invalid API response:", e);
  }
}
