import { IAuthSettings } from "@/interfaces/IAuthSettings";
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import getConfig from "next/config";
import Keycloak from "keycloak-js";
import { deleteCookie, getCookie, setCookie } from "@/common/utility/cookie";
import { useRouter } from "next/router";
import addDays from "date-fns/addDays";
import { trackLogin, getFavouriteDashboard } from "@/services/useAccount";

const AuthSettingsContext = React.createContext<any | null>(null);

export function useAuthSettings() {
  return useContext(AuthSettingsContext);
}

export function AuthSettingsProvider({ children }) {
  const router = useRouter();
  const [isFetchAuthSettings, setIsFetchAuthSettings] = useState(true);
  const { publicRuntimeConfig } = getConfig();
  const keycloakAdapter = useRef<Keycloak>(null);

  const getAuthSettings = async () => {
    try {
      const params = new URLSearchParams(window.location.search);
      let subdomain = "";

      if (params.has("oid")) {
        const oidValue = params.get("oid");
        if (oidValue && oidValue.trim() !== "") {
          subdomain = oidValue;
        }
      }

      if (!subdomain) {
        const urlParts = window.location.hostname.split(".");
        if (urlParts.length >= 3) {
          subdomain = urlParts[0];
        }
        if (
          subdomain === "demo" ||
          subdomain === "test" ||
          subdomain === "dev" ||
          subdomain === "uat" ||
          subdomain === "v3" ||
          subdomain === "dev-v3" ||
          subdomain === "uat-v3" ||
          subdomain === "prod-v3" ||
          subdomain === "v4" ||
          subdomain === "dev-v4" ||
          subdomain === "uat-v4" ||
          subdomain === "prod-v4"
        ) {
          subdomain = "";
        }
      }

      let authSettingsUrl = `${publicRuntimeConfig.API_URL}/organisation/authSettings?subdomain=${subdomain}`;
      const response = await fetch(authSettingsUrl);

      if (response.ok) {
        const authSettings = await response.json();
        return authSettings;
      }
      return null;
    } catch (error) {
      console.error("Error fetching auth settings:", error);
      return null;
    }
  };

  const initializeKeycloak = (authOptions: any) => {
    try {
      const keycloak = new Keycloak(authOptions);

      keycloak
        .init({
          onLoad: "login-required",
          checkLoginIframe: false,
        })
        .then(async (authenticated) => {
          if (authenticated && !getCookie("token")) {
            localStorage.removeItem("authSettings");
            const tokenExpiration = keycloak.tokenParsed.exp;
            const tokenExpiresAt = new Date(tokenExpiration * 1000);
            const authSettings = {
              authOptions: authOptions,
              token: keycloak.token,
              refreshToken: keycloak.refreshToken,
              createdDate: new Date(),
              tokenExpiresAt: tokenExpiresAt,
            };

            localStorage.setItem("authSettings", JSON.stringify(authSettings));
            setCookie("realm", authOptions.realm, addDays(new Date(), 30));
            setCookie("token", keycloak.token, tokenExpiresAt);

            const response = await trackLogin(false).catch((x) =>
              console.log("Error when tracking login:", x)
            );

            if (response?.ok) {
              const dashboard = await getFavouriteDashboard().catch((x) =>
                console.log("Error when fetching fav dashboard:", x)
              );

              if (dashboard?.favoriteDashboard) {
                localStorage.setItem(
                  "favoriteDashboard",
                  dashboard?.favoriteDashboard
                );
                router.push("/dashboard/" + dashboard.favoriteDashboard);
                router.push(
                  "/dashboard/" +
                    dashboard.favoriteDashboard.replace("/dashboard/", "")
                );
              } else {
                router.push("/dashboard/dashboard_01");
              }
            } else {
              console.log("Failed to user's login");
            }
          } else {
            console.log("is not authenticated");
          }
        })
        .catch((e) => console.log(`keycloak init exception: ${e}`));

      keycloakAdapter.current = keycloak;
    } catch (error) {
      console.error("Keycloak initialization error:", error);
    }
  };

  const fetchAuthSettings = async () => {
    if (!getCookie("token")) {
      await getAuthSettings()
        .then((authSettings) => {
          if (authSettings) {
            const authOptions = {
              url: `${authSettings.authServerUrl}/auth`,
              realm: authSettings.realm,
              clientId: authSettings.clientId,
              onLoad: "login-required",
              KeycloakResponseType: "code",
            };
            initializeKeycloak(authOptions);
          }
        })
        .catch((e) => console.log(`fetchAuthSettings exception: ${e}`));
    } else {
      if (localStorage.getItem("favoriteDashboard") != null) {
        router.push(
          "/dashboard/" +
            localStorage.getItem("favoriteDashboard").replace("/dashboard/", "")
        );
      } else {
        router.push("/dashboard/dashboard_01");
      }
    }
  };

  const logOut = (realm) => {
    localStorage.removeItem("authSettings");
    deleteCookie("organisationId");
    deleteCookie("realm");
    deleteCookie("token");

    let redirectUri = `https://${window.location.hostname}`;
    if (process.env.NODE_ENV != "production") {
      redirectUri = `http://${window.location.hostname}:3000`;
    }

    if (realm != null) {
      window.location.href = `${publicRuntimeConfig.DEFAULT_AUTH_SERVER}/auth/realms/${realm}/protocol/openid-connect/logout?redirect_uri=${redirectUri}`;
    }

    fetchAndLogOut(redirectUri);
  };

  const fetchAndLogOut = async (redirectUri) => {
    try {
      const authSettings = await getAuthSettings();
      window.location.href = `${publicRuntimeConfig.DEFAULT_AUTH_SERVER}/auth/realms/${authSettings.realm}/protocol/openid-connect/logout?redirect_uri=${redirectUri}`;
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const contextValue = useMemo(
    () => ({
      fetchAuthSettings,
      logOut,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (isFetchAuthSettings) {
      fetchAuthSettings();
      setIsFetchAuthSettings(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchAuthSettings]);

  return (
    <AuthSettingsContext.Provider value={contextValue}>
      {children}
    </AuthSettingsContext.Provider>
  );
}
