import * as React from "react";

const NAME = "Auth";
const TYPE_FETCH = `${NAME}_FETCH`;
const TYPE_ERROR = `${NAME}_ERROR`;
const TYPE_COMPLETE = `${NAME}_COMPLETE`;

const AuthContext = React.createContext<any | null>(null);

function authReducer(state, action) {
  switch (action.type) {
    case TYPE_FETCH: {
      return { ...state, fetch: true };
    }
    case TYPE_COMPLETE: {
      return { ...state, fetch: false, ...action?.payload };
    }
    case TYPE_ERROR: {
      return { ...state, fetch: false, ...action?.payload };
    }
    default: {
      throw new Error(`Unsupported action type: ${action.type}`);
    }
  }
}

function AuthProvider(props) {
  const [state, dispatch] = React.useReducer(authReducer, {
    fetch: false,
    error: {},
    data: {},
  });
  const value = React.useMemo(() => [state, dispatch], [state]);

  return <AuthContext.Provider value={value} {...props} />;
}

export { AuthProvider };
